import * as React from "react";
import Slideshow from "../Slideshow/Slideshow";
import FreeShippingBanner from "../FreeShippingBanner/FreeShippingBanner";
import Categories from "../Categories/Categories";
import TopSeller from "../TopSeller/TopSeller";
import Teaser from "../Teaser/Teaser";
import OurProjects from "../OurProject/OurProjects";
import Content from "../Content/Content";
import Team from "../Team/Team";
import BlurContent from "../BlurContent/BlurContent";
import FairpacktBanner from "../FairpacktBanner/FairpacktBanner";
import FreeShipping from "../FreeShipping/FreeShipping";
import Headline from "../Headline/Headline";
import ProductOverview from "../ProductOverview/ProductOverview";
import CallToActionButton from "../CallToActionButton/CallToActionButton";

function getIsHome(pageTitle) {
    return pageTitle.includes("Home");
}

function Element(props: { data: any, pageTitle: any }) {
    switch (props.data.ClassName) {
        case "CIC\\App\\Elements\\Slideshow\\Slideshow":
            return <Slideshow SlideshowItems={
                props.data.SlideshowItems
            } isHome={getIsHome(props.pageTitle)}
            />
        case "CIC\\App\\Elements\\FreeShippingBanner\\FreeShippingBanner":
            return <FreeShippingBanner text1={props.data.Text1 ?? null} text2={props.data.Text2 ?? null} text3={props.data.Text3 ?? null} text4={props.data.Text4 ?? null}/>
        case "CIC\\App\\Elements\\FairPackt\\FairPackt":
            return <FairpacktBanner text={props.data.Text ?? null}/>
        case "CIC\\App\\Elements\\FreeShipping\\FreeShipping":
            return <FreeShipping amount={props.data.Amount ?? null}/>
        case "CIC\\App\\Elements\\Categories\\Categories":
            return <Categories heading={props.data.CategoryTitle}/>
        case "CIC\\App\\Elements\\TopSeller\\TopSeller":
            return <TopSeller title={props.data.Title ?? "Top-Seller"} type={props.data.PreviewType ?? "random"}/>
        case "CIC\\App\\Elements\\CallToActionButton\\CallToActionButton":
            return <CallToActionButton link={props.data.ButtonLink} text={props.data.ButtonText} align={props.data.Alignment}/>
        case "CIC\\App\\Elements\\Headline\\Headline":
            return <Headline title={props.data.Title ?? "Top-Seller"} seo={props.data.SEO ?? "Top-Seller"} align={props.data.Alignment}/>
        case "CIC\\App\\Elements\\ProductOverview\\ProductOverview":
            return <ProductOverview products={props.data.Products} ID={props.data.ID} ShowFilter={props.data.ShowFilter}/>
        case "CIC\\App\\Elements\\Teaser\\Teaser":
            return <Teaser
                heading={props.data.Title}
                description={props.data.Subtitle}
                background={props.data.BackgroundImageLink}
                items={props.data.TeaserItems}
            />
        case "CIC\\App\\Elements\\OurProjects\\OurProjects":
            return <OurProjects title={props.data.Title} text={props.data.Text} projects={props.data.Projects}/>;
        case "CIC\\App\\Elements\\Content\\Content":
            return <Content
                title={props.data.Title}
                text={props.data.Text}
                mediaPos={props.data.MediaPos}
                imageLink={props.data.ImageLink}
                imageUrl={props.data.ImageURL}
                TextAlign={props.data.TextAlign}
                smallImage={props.data.smallImage}
            />;
        case "CIC\\App\\Elements\\Team\\Team":
            return <Team
                members={props.data.TeamMembers}
                heading={props.data.Heading}
            />
        case "CIC\\App\\Elements\\BlurContent\\BlurContent":
            return <BlurContent
                title={props.data.Title}
                text={props.data.Text}
                imageUrl={props.data.ImageURL}
                file={props.data.FileURL}
                TextAlign={props.data.TextAlign}
                buttonText={props.data.ButtonText}
                background={props.data.BackgroundImageURL}
                ButtonLink={props.data.ButtonLink}
                ShowBGBottom={props.data.ShowBGBottom}
                ElementLink={props.data.CustomElementLink}
             ButtonEnabled={props.data.ButtonEnabled}/>;

        default:
            return <div>
                This will be an element of the type {props.data.ClassName}, but there is still work to be done!
            </div>
    }
}

export default Element;
