import * as React from "react";
import {FC} from "react";
import {Button, Carousel, Col, Row} from "react-bootstrap";
// @ts-ignore
import * as style from './Slideshow.module.scss';
import {Link} from "gatsby";
import './slideshow.scss';
import classNames from "classnames/bind";

type SlideshowItem = {
    ButtonEnabled: number,
    ButtonFileID: number,
    ButtonLink: null,
    ButtonLinkType: "string",
    ButtonPage: "string",
    ButtonPos: "string",
    ButtonText: "string",
    ClassName: "string",
    HolderID: number,
    ID: number,
    IconID: number,
    IconLink: "string",
    ImageID: number,
    ImageLink: "string",
    MobileImageID: number,
    MobileImageLink: "string",
    VideoLink: "string",
    VideoTextImageLink: "string",
    Title: "string",
}

type SlideshowProps = {
    SlideshowItems: Array<SlideshowItem>,
    isHome: boolean
}


const Slideshow: FC<SlideshowProps> = (props) => {

    let cl = classNames.bind(style);
    return (
        <>
            <Carousel fade controls={false} indicators={true} className={style.slideshow + (props.isHome ? ' headerSlideshow' : ' ')}>
                {props.SlideshowItems.map((slideshowItem: SlideshowItem, index) => {
                    return (
                        <Carousel.Item className={style.slideshowItemHolder} key={index}>
                            {slideshowItem.VideoLink ?
                                <div className={style.landingPageContainer}>
                                    <video src={slideshowItem.VideoLink} muted={true} playsInline autoPlay loop className={cl({
                                        isMobileBackground: false,
                                        slideshowBackground: !slideshowItem.Title,
                                        slideshowBackgroundBrightness: slideshowItem.Title
                                    })} />
                                    <div className={style.greenOverlay}>
                                    </div>
                                    {/*<div className={style.pinkBoxOverlay}>*/}
                                    {/*    <div className={style.pinkBox}>*/}
                                    {/*        <span>PLASTIKFREI*/}
                                    {/*        <br/>EINKAUFEN.*/}
                                    {/*        <br/>REGIONAL.*/}
                                    {/*        <br/>HANDGEMACHT.*/}
                                    {/*        <br/>FÜR JEDEN.*/}
                                    {/*        </span>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <img src={slideshowItem.VideoTextImageLink} className={style.imgOverlay}/>
                                </div>:
                                <img src={slideshowItem.ImageLink} className={cl({
                                    isMobileBackground: false,
                                    slideshowBackground: !slideshowItem.Title,
                                    slideshowImage: true,
                                    slideshowBackgroundBrightness: slideshowItem.Title
                                })}/>
                            }
                            {slideshowItem.MobileImageLink ?

                                <img src={slideshowItem.MobileImageLink} className={cl({
                                    isMobileBackground: true,
                                    slideshowBackground: !slideshowItem.Title,
                                    slideshowBackgroundBrightness: slideshowItem.Title
                                })}/> : null
                            }
                            <Carousel.Caption className={style.slideshowCaption}>
                                {slideshowItem.IconLink ?
                                    <img src={slideshowItem.IconLink} className={style.icon + ' img-fluid'}/> : null
                                }
                                {slideshowItem.Title ? <h3 className={style.heading}>{slideshowItem.Title}</h3> : null}
                                {slideshowItem.ButtonEnabled === 1 ?
                                    <Row>
                                        <Col lg={12} md={12} xs={12} className={'mb-3 m-lg-0'}>
                                            <Button className={style.button + ' w-100'} as={Link}
                                                    to={slideshowItem.ButtonLink}>
                                                <p className={style.text}> {slideshowItem.ButtonText} </p>
                                            </Button>
                                        </Col>
                                    </Row> : null
                                }
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                })}
            </Carousel>
            {props.isHome ? <div className={'d-md-none d-flex justify-content-center'}>
                <Link className={'slideshowCTA'} to={'/marktplatz'} style={{textDecoration: 'none', color: '#FFFFFF'}}>
                    Zum Markt
                </Link>
            </div> : null}
        </>
    )
}

export default Slideshow;
