import * as React from "react";
// @ts-ignore
import * as style from './CallToActionButton.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {Link} from "gatsby";

import {Button} from "bootstrap";

type CallToActionButtonProps = {
    link: string,
    text: string,
    align: string,
}


function CallToActionButton(props: CallToActionButtonProps) {
    return <>
        <Container className={'d-flex '  + props.align}>
            <Link to={`https://${props.link}`} className={style.customButton}>
                {props.text}
            </Link>
        </Container>
    </>;
}

export default CallToActionButton;
