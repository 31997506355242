// extracted by mini-css-extract-plugin
export var container = "Team-module--container--MWtlu";
export var customContainer = "Team-module--customContainer--ClYWn";
export var customRow = "Team-module--customRow--pQysH";
export var email = "Team-module--email--kMeO8";
export var image = "Team-module--image--Mhfvs";
export var mainHeading = "Team-module--mainHeading--iTdIM";
export var member = "Team-module--member--afv3i";
export var name = "Team-module--name--tFQX0";
export var phone = "Team-module--phone--vKhkS";
export var position = "Team-module--position--PQBUn";