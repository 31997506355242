import * as React from "react";
// @ts-ignore
import {bannerBG, icon, text, responsiveContainer, shippingBannerItem} from './FreeShippingBanner.module.scss';
import {faBoxHeart, faHandHoldingSeedling, faMapMarkedAlt, faHandsHeart} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Client, handleApiError} from "../../services/ApiService";

const FreeShippingBanner = (props: { text1?: string, text2?: string, text3?: string, text4?: string, disableMobile?: boolean }) => {
    const [firstIcon, setFirstIcon] = useState('');
    const [secondIcon, setSecondIcon] = useState('');
    const [thirdIcon, setThirdIcon] = useState('');
    const [fourthIcon, setFourthIcon] = useState('');

    useEffect(() => {
        Client.Raw.get('SiteConfig/1').then(handleResult).catch(handleApiError);
    }, [])

    function handleResult(res) {
        setFirstIcon(res.data.ShippingIconText1);
        setSecondIcon(res.data.ShippingIconText2);
        setThirdIcon(res.data.ShippingIconText3);
        setFourthIcon(res.data.ShippingIconText4);
    }


    return (
        <>
            <div className={bannerBG + (props.disableMobile ? ' d-none d-md-block' : '')}>
                <Container className={'py-5'}>
                    <Row>
                        <Col lg={3} xs={6}
                             className={'d-flex align-items-center justify-content-lg-center pb-4 pb-lg-0 ' + shippingBannerItem}>
                            <a className={'d-flex align-items-center justify-content-lg-center text-decoration-none'} href={firstIcon}>
                                <FontAwesomeIcon icon={faHandHoldingSeedling} className={icon}/>
                                <p className={text}>{props.text1 ?? 'Schön, dass du hier bist!'}</p>
                            </a>
                        </Col>
                        <Col lg={3} xs={6}
                             className={'d-flex align-items-center justify-content-lg-center pb-4 pb-lg-0 ' + shippingBannerItem}>
                            <a className={'d-flex align-items-center justify-content-lg-center text-decoration-none'} href={secondIcon}>

                                <FontAwesomeIcon icon={faBoxHeart} className={icon}/>
                                <p className={text}>{props.text2 ?? 'Schön, dass du hier bist!'}</p>
                            </a>

                        </Col>
                        <Col lg={3} xs={6}
                             className={'d-flex align-items-center justify-content-lg-center ' + shippingBannerItem}>
                            <a className={'d-flex align-items-center justify-content-lg-center text-decoration-none'} href={thirdIcon}>

                                <FontAwesomeIcon icon={faMapMarkedAlt} className={icon}/>
                                <p className={text}>{props.text3 ?? 'Schön, dass du hier bist!'}</p>
                            </a>

                        </Col>
                        <Col lg={3} xs={6}
                             className={'d-flex align-items-center justify-content-lg-center ' + shippingBannerItem}>
                            <a className={'d-flex align-items-center justify-content-lg-center text-decoration-none'} href={fourthIcon}>

                                <FontAwesomeIcon icon={faBoxHeart} className={icon}/>
                                <p className={text}>{props.text4 ?? 'Schön, dass du hier bist!'}</p>
                            </a>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default FreeShippingBanner;
