import * as React from "react";
//@ts-ignore
import * as style from './Content.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
// @ts-ignore
import {SRLWrapper} from "simple-react-lightbox";
import classNames from "classnames/bind";


function Content(props: { title?: string, text?: string, mediaPos: string, imageLink?: string, imageUrl?: string, TextAlign?: string, smallImage?: boolean }) {
    let cl = classNames.bind(style);
    const isFullSize = props.mediaPos === 'Top' || props.mediaPos === 'Bottom';
    const justify = {'text-left': 'justify-content-start', 'text-center': 'justify-content-center', 'text-right': 'justify-content-end'}[props.TextAlign ?? 'text-left'];
    return <Container className={style.contentElement}>
        <SRLWrapper>
            <Row className={"align-items-center"}>

                {(props.mediaPos === 'Right' || props.mediaPos === 'Bottom') ?
                    <Col lg={isFullSize ? 12 : 6} md={isFullSize ? 12 : 6}
                         className={"d-flex align-items-center mb-3 mb-md-0 " + props.TextAlign + ' ' + justify}>
                        <div className={props.mediaPos === 'Bottom' ? 'mb-4' : ''}>
                            {props.title && <h2>{props.title}</h2>}

                            <ReactMarkdown children={props.text ?? ''} remarkPlugins={[remarkGfm]}/>
                        </div>
                    </Col> : null}

                {props.imageUrl && <Col lg={isFullSize ? 12 : 6} md={isFullSize ? 12 : 6}>
                    {props.imageLink ?
                        <a href={props.imageLink} target="_blank">
                            <img src={props.imageUrl} className={cl({
                                image: true,
                                smallImage: props.smallImage
                            })} alt={props.title ?? 'Bild'}/>
                        </a>
                        :
                        <img src={props.imageUrl} className={cl({
                            image: true,
                            smallImage: props.smallImage
                        })} alt={props.title ?? 'Bild'}/>
                    }
                </Col>}
                {(props.mediaPos === 'Left' || props.mediaPos === 'Top') ?
                    <Col lg={isFullSize ? 12 : 6} md={isFullSize ? 12 : 6}
                         className={"d-flex align-items-center mt-3 mt-md-0 order-md-last order-first " + props.TextAlign + ' ' + justify}>
                        <div
                            className={props.mediaPos === 'Top' ? 'mt-4' : ''}>
                            {props.title && <h2>{props.title}</h2>}
                            <ReactMarkdown children={props.text ?? ''}/>
                        </div>
                    </Col> : null}
            </Row>
        </SRLWrapper>
    </Container>;
}

export default Content;
