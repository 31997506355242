import * as React from "react";
// @ts-ignore
import {bannerBG, icon, text, responsiveContainer, shippingBannerItem, headline, desc, textContainer, customRow, imgContainer} from './FairpacktBanner.module.scss';
import {faBoxHeart, faHandHoldingSeedling, faMapMarkedAlt, faHandsHeart} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Client, handleApiError} from "../../services/ApiService";
import {StaticImage} from "gatsby-plugin-image";

const FairpacktBanner = (props: { text?: string, disableMobile?: boolean }) => {
    const [firstIcon, setFirstIcon] = useState('');
    const [secondIcon, setSecondIcon] = useState('');
    const [thirdIcon, setThirdIcon] = useState('');
    const [fourthIcon, setFourthIcon] = useState('');

    useEffect(() => {
        Client.Raw.get('SiteConfig/1').then(handleResult).catch(handleApiError);
    }, [])

    function handleResult(res) {
        setFirstIcon(res.data.ShippingIconText1);
        setSecondIcon(res.data.ShippingIconText2);
        setThirdIcon(res.data.ShippingIconText3);
        setFourthIcon(res.data.ShippingIconText4);
    }


    return (
        <>
            <div className={ (props.disableMobile ? ' d-none d-md-block' : 'py-5')}>
                <Container className={bannerBG + ' py-5'}>
                    <Row className={customRow}>
                        <Col md={6} className={'d-flex align-items-center justify-content-lg-center pb-lg-0 ' + shippingBannerItem}>
                            <StaticImage
                                className={imgContainer}
                                src={'../../images/fairPacktWeiß.png'}
                                placeholder="tracedSVG"
                                alt="image"
                            />
                        </Col>
                        <Col  md={6} className={textContainer + ' d-flex align-items-center justify-content-lg-center pb-lg-0 ' + shippingBannerItem}>
                            <div>
                                <div className={headline}>Fairliebt. Fairpackt. Fairsendet.</div>
                                <div className={desc}>Nicht nur unsere Produkte sind fair zur Umwelt, sondern auch der Weg auf dem sie zu dir nach Hause kommen. Daher achten wir darauf, dass die Bestellmenge je fairstärkt-Händler € 25,- nicht unterschreitet und der logistische und ökologische Aufwand in Relation stehen. Quasi FAIRPACKT.</div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default FairpacktBanner;
