import * as React from "react";
// @ts-ignore
import {container, innerContainer, projectImage, background} from './OurProjects.module.scss';
import {Col, Row} from "react-bootstrap";
import {StaticImage} from "gatsby-plugin-image";

const OurProjects = (props: { title?: string, text?: string, projects?: Array<{ Link: string, ImageLink: string, }> }) => {
    return (
        <div className={container}>
            <StaticImage src={'../../images/login-hg-2x.jpg'} alt={"background"} placeholder={"blurred"} className={background} />
            <div className={innerContainer}>
                {props.title && <h2>{props.title}</h2>}
                {props.text && <span>{props.text}</span>}
                <Row className="mt-5 w-100 justify-content-center">
                    {props.projects && props.projects.map((project: { Link: string, ImageLink: string, }, index: number) =>
                        <Col lg={3} xs={12} className={"mb-5 mb-md-0"} key={index}>
                            {project.Link && project.Link.length ?
                                <a href={project.Link} target="_blank">
                                    <img src={project.ImageLink} className={projectImage} alt={'Projekt'}/>
                                </a>
                                :
                                <img src={project.ImageLink} className={projectImage} alt={'Projekt'}/>
                            }
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    )
}

export default OurProjects;
