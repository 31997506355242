import * as React from "react";
import {FC} from "react";
// @ts-ignore
import * as style from './Team.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import {SRLWrapper} from "simple-react-lightbox";

type Props = {
    members: any,
    heading: string
}


const Team: FC<Props> = ({members, heading}) => {
    return (
        <div className={style.container}>
            <Container className={style.customContainer}>
                <h2 className={style.mainHeading}>{heading}</h2>
                <SRLWrapper>
                    <div>
                        {members?.map((member, index) => {
                            console.log(index)
                            return (
                                <Row className={style.customRow}>
                                    <Col lg={index % 2  == 0 ? 6 : {order: "last", span: 6}} md={12} key={index} className={style.member + ' mb-5 mb-lg-0'}>
                                        <img src={member.MainImageLink} className={"img-fluid " + style.image}/>
                                    </Col>
                                    <Col lg={index % 2  == 0 ? 6 : {order: 1, span: 6}} md={12} >
                                        <h4 className={style.name}>{member.Title}</h4>
                                        <h4 className={style.position}> {member.Position}</h4>
                                        <span className={style.position + ' my-2'}>
                                    {member.Description}
                                </span>
                                    </Col>
                                    <a href={`tel:${member.Phone}`} style={{textDecoration: 'none'}}>
                                        <h5 className={style.phone}>{member.Phone}</h5>
                                    </a>
                                    <a href={`mailto:${member.Email}`} style={{textDecoration: 'none'}}>
                                        <h5 className={style.email}>{member.Email}</h5>
                                    </a>

                                </Row>
                            )
                        })}
                    </div>
                </SRLWrapper>
            </Container>
        </div>
    )
}

export default Team;
