import * as React from "react";
import {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
// @ts-ignore
import * as style from './Categories.module.scss';
import {Client, handleApiError} from "../../services/ApiService";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

type Props = {
    catID: any
    isChildrenCat: boolean,
    isHomePage: boolean
}

const Categories = (props) => {
    const [categories, setCategories] = useState([]);
    const [title, setTitle] = useState('')

    useEffect(() => {
        Client.Raw.get(props.catID ? 'Category/' + props.catID + '/getChildCategories' : 'Category/getRelevantCategories').then(handleResult).catch(handleApiError);
        if (props.catID) {
            Client.Raw.get('Category/' + props.catID).then((res) => {
                setTitle(res.data.Title)
            })
        }

    }, [])

    function handleResult(res) {
        setCategories(res.data);
    }

    return (
        <div className={style.bgBeige}>
            <Container className={'pb-5'}>
                {title ?
                <Row>
                    <Col xs={12}>
                        <h1 className={style.heading + ' text-center'}>{props.heading ? props.heading : (title ? 'Regionale ' + title : null)} </h1>
                    </Col>
                </Row>
                : null}
                <Row className={"mb-5 justify-content-center"}>
                    {categories.map((item: any, index: number) => {
                            return (
                                <>
                                    {(props.isNotHomePage && item.ProductCount === 0) ? null :
                                        <Col md={6} lg={4} xs={6} key={index} className={' text-center'}>
                                            <a href={`/marktplatz?catid=${item.ID}`} style={{textDecoration: 'none', color: 'unset'}}>
                                                <div className={style.categoryBackground}>
                                                    <div className={style.categoryIconHolder}
                                                         style={{textDecoration: 'none', color: 'unset'}}>
                                                        <div className={style.iconHolder}>
                                                            {item.MainImageLink ?
                                                                <img src={item.MainImageLink}
                                                                     className={props.isNotHomePage ? style.noHomePageIcon + ' img-fluid' : style.icon + ' img-fluid'}
                                                                     alt={item.Title}/> :
                                                                <img src={item.FirstProduct?.PreviewImage}
                                                                     className={props.isNotHomePage ? style.noHomePageIcon + ' img-fluid' : style.icon + ' img-fluid'}
                                                                     alt={item.Title}/>}

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={style.textContainer}>
                                                    <p className={props.isNotHomePage ? style.noHomePageDesc : style.description}>{item.Title}</p>
                                                    <p className={style.productCount}>{item.ProductCount !== 1 ? (item.ProductCount
                                                        + ' Produkte') : (item.ProductCount + ' Produkt')}</p>
                                                </div>
                                            </a>
                                        </Col>
                                    }


                                </>
                            )
                        }
                    )}
                </Row>
                <div className={style.buttonContainer}>
                <Link to={'/marktplatz'}
                   className={style.customButton}> Zum Marktplatz </Link>
                </div>
            </Container>
        </div>
    )
}

export default Categories;
