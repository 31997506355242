import * as React from "react";
// @ts-ignore
import * as style from './Headline.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {Link} from "gatsby";
import {Button} from "bootstrap";

type HeadlineProps = {
    title: string,
    seo: string,
    align: string,
}


function Headline(props: HeadlineProps) {
    const SeoTag = props.seo.toLowerCase();

    const tagMap: { [key: string]: React.ElementType } = {
        "h1": "h1",
        "h2": "h2",
    };

    const DynamicTag = tagMap[SeoTag] || "div";

    return (
        <Container className={style.contentElement}>
                <Row className={"align-items-center"}>
                    <Col lg={12} className={"d-flex align-items-center mb-3 mb-md-0 text-left " + props.align}>
                        <div>
                            <DynamicTag
                                className={style.headline}
                                style={{
                                    textAlign: props.align === "justify-content-end" ? "right" : "left"
                                }}
                            >
                                {props.title}
                            </DynamicTag>
                        </div>
                    </Col>
                </Row>
        </Container>
    );
}

export default Headline;
