// extracted by mini-css-extract-plugin
export var bgBeige = "Categories-module--bgBeige--rbTDT";
export var buttonContainer = "Categories-module--buttonContainer--u5HQj";
export var categoryBackground = "Categories-module--categoryBackground--dszhU";
export var categoryIconHolder = "Categories-module--categoryIconHolder--eUy7V";
export var customButton = "Categories-module--customButton--lauWA";
export var description = "Categories-module--description--LN7ZY";
export var heading = "Categories-module--heading--pLLuQ";
export var icon = "Categories-module--icon--M9e91";
export var iconHolder = "Categories-module--iconHolder--lpnv7";
export var noHomePageDesc = "Categories-module--noHomePageDesc--LjNsz";
export var noHomePageIcon = "Categories-module--noHomePageIcon--xrk4J";
export var productCount = "Categories-module--productCount--r986A";
export var textContainer = "Categories-module--textContainer--rfIQV";