import * as React from "react";
// @ts-ignore
import {} from './FreeShipping.module.scss';
import {faBoxHeart, faHandHoldingSeedling, faMapMarkedAlt, faHandsHeart} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Client, handleApiError} from "../../services/ApiService";
import {StaticImage} from "gatsby-plugin-image";
import {bannerBG, shippingBannerItem, text} from "../FreeShipping/FreeShipping.module.scss";

const FreeShipping = (props: { amount?: string }) => {

    return (
        <>
            <div className={bannerBG}>
                <Container className={'py-5'}>
                    <div className={' align-items-center justify-content-lg-center pb-4 pb-lg-0 ' + shippingBannerItem}>
                        <div>
                            <p className={text}>{"ab einem Bestellwert von " + props.amount + "€ pro Händler"}</p>
                        </div>

                        <div>
                            <h2 className={text}>{"Versandkostenfrei"}</h2>
                        </div>
                    </div>
                </Container>
            </div>
        </>
)
}

export default FreeShipping;
