// extracted by mini-css-extract-plugin
export var container = "Teaser-module--container--izWJZ";
export var customButton = "Teaser-module--customButton--2EAk9";
export var customButtonMobile = "Teaser-module--customButtonMobile--sTJFD";
export var customContainer = "Teaser-module--customContainer--ql25V";
export var headingHolder = "Teaser-module--headingHolder--mCPTk";
export var headingStyle = "Teaser-module--headingStyle--DR5NF";
export var holder = "Teaser-module--holder--F-BY9";
export var icon = "Teaser-module--icon--vYs3S";
export var iconHolder = "Teaser-module--iconHolder--lGe7e";
export var link = "Teaser-module--link--RWg9s";
export var subHeading = "Teaser-module--subHeading--p6AK3";
export var teaserDesc = "Teaser-module--teaserDesc--dvx-L";
export var teaserHeading = "Teaser-module--teaserHeading--g1jVe";
export var teaserIcon = "Teaser-module--teaserIcon--GPld0";
export var test = "Teaser-module--test--I2pIR";
export var textContainer = "Teaser-module--textContainer--U4tAa";
export var wiggle = "Teaser-module--wiggle--Gl3TY";