// extracted by mini-css-extract-plugin
export var button = "Slideshow-module--button--1cKKy";
export var greenOverlay = "Slideshow-module--greenOverlay--8Ze0Z";
export var heading = "Slideshow-module--heading--T9wQp";
export var icon = "Slideshow-module--icon--gZtKR";
export var imgOverlay = "Slideshow-module--imgOverlay--6p3FB";
export var isMobileBackground = "Slideshow-module--isMobileBackground--yH9+c";
export var landingPageContainer = "Slideshow-module--landingPageContainer--JOF7i";
export var pinkBox = "Slideshow-module--pinkBox--92Qa6";
export var pinkBoxOverlay = "Slideshow-module--pinkBoxOverlay--GTT2e";
export var slideshow = "Slideshow-module--slideshow--VlbIG";
export var slideshowBackground = "Slideshow-module--slideshowBackground--L1B+X";
export var slideshowBackgroundBrightness = "Slideshow-module--slideshowBackgroundBrightness--xzhkE";
export var slideshowCaption = "Slideshow-module--slideshowCaption--BygW3";
export var slideshowImage = "Slideshow-module--slideshowImage--wsQrQ";
export var slideshowItemHolder = "Slideshow-module--slideshowItemHolder--RityJ";
export var text = "Slideshow-module--text--r93tY";