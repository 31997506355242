import * as React from "react";
import {FC} from "react";
import {Carousel, Col, Row} from "react-bootstrap";
// @ts-ignore
import * as style from './Teaser.module.scss';
import {Link} from "gatsby";
import './teaser.scss';
import {Button} from "bootstrap";
import AccordionButton from "react-bootstrap/cjs/AccordionButton";
import {faCheck} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type TeaserProps = {
    heading: string,
    background: string,
    description: string,
    items: Array<object>
}

const Teaser: FC<TeaserProps> = ({items, heading, description, background}) => {

    return (
        <div className={style.container} style={{backgroundImage: `url(${background})`}}>
            <div  className={style.link}>
                <div className={style.customContainer + " container"}>
                    <Row>
                        <Col lg={6}>
                            <div className={style.headingHolder}>
                                <h3 className={style.headingStyle}>{heading}</h3>
                                <p className={style.subHeading}>{description}</p>
                                <a href="/s/unsere-vision" className={style.customButton}>MEHR ÜBER DIE VISION ERFAHREN</a>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <Row className={style.textContainer}>
                                {items && items.map((item: any, index: number) =>
                                    <Row className={style.holder} key={index}>
                                        <Col lg={1} xs={1} className={style.iconHolder}>
                                            <FontAwesomeIcon icon={faCheck} className={style.teaserIcon}/>
                                        </Col>
                                        <Col lg={11} xs={11}>
                                            <h4 className={style.teaserHeading}> {item.Title}</h4>
                                            <p className={style.teaserDesc}>{item.Description}</p>
                                        </Col>
                                    </Row>
                                )}
                                <a href="/s/unsere-vision" className={style.customButtonMobile}>MEHR ÜBER DIE VISION ERFAHREN</a>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Teaser
